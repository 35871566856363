import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async rowPedidoCliente (Vue, idpedidocli) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idpedidocli', idpedidocli)
    const resp = await Vue.$api.call('pedidocli.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectLineaPedidoCliente (Vue, idpedidocli, idtlineaProduccion) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idpedidocli', idpedidocli)
      .addGT('unidades_falta_servir', 0)
    if (idtlineaProduccion) {
      apiFilter.addExact('idtlinea_produccion', idtlineaProduccion)
    }
    const resp = await Vue.$api.call('lpedidocli.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async selectTlineaProduccion (Vue) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('tlineaProduccion.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async rowArticulo (Vue, idarticulo) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idarticulo', idarticulo)
    const resp = await Vue.$api.call('articulo.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async rowArticuloCodBarras (Vue, codbarras) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('codbarras', codbarras)
    const resp = await Vue.$api.call('articulo.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async idalbarancliAServir (Vue, idpedidocli) {
    const resp = await Vue.$api.call('albarancli.idalbarancliAServir', {
      idpedidocli
    })
    if (resp && resp.data && resp.data.result) {
      return resp.data.result.dataset[0]
    }
  },
  async crearAlbaranDesdePedido (Vue, idpedidocli, fecha) {
    const resp = await Vue.$api.call('albarancli.crearDesdePedido', {
      idpedidocli, fecha
    })
    return resp.data.result.dataset[0]
  },
  anadirArticuloAlbaran (Vue, idlpedidocli, idalbarancli, nserie, mezcla, lote, ffabricacion, codfabricacion) {
    Vue.$api.call('lalbarancli.anadirArticulo', {
      idlpedidocli, idalbarancli, nserie, mezcla, lote, ffabricacion, codfabricacion
    })
  },
  anadirArticuloAlbaranSinPedido (Vue, idalbarancli, idarticulo, nserie, mezcla, lote, ffabricacion, codfabricacion) {
    Vue.$api.call('lalbarancli.anadirArticuloAlbaranSinPedido', {
      idalbarancli, idarticulo, nserie, mezcla, lote, ffabricacion, codfabricacion
    })
  },
  async convertirQR (Vue, qr) {
    const codigo = qr.substring(0, 6)
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('codigo', codigo)
    const resp = await Vue.$api.call('articulo.select', { filter: apiFilter })
    if (resp.data.result.dataset.length > 0) {
      const articulo = resp.data.result.dataset[0]
      return {
        idarticulo: articulo.idarticulo,
        mezcla: qr.substring(7, 12),
        lote: qr.substring(13, 17),
        ffabricacion: qr.substring(18, 24),
        nserie: qr.substring(25, 99)
      }
    }
  }
}
